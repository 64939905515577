import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
	getListConsultantUserService,
	getListConsultantUserServiceById,
	getConsultantReportUser,
} from "../../api/summary-service";
import SweetAlert from "sweetalert2";

export const getConsultantServiceAction = createAsyncThunk(
	"summaryService/getListUser",
	async (body, { rejectWithValue }) => {
		try {
			const response = await getListConsultantUserService(body); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getConsultantReportUserAction = createAsyncThunk(
	"summaryService/getConsultantReportUserAction",
	async (_, { rejectWithValue }) => {
		try {
			const response = await getConsultantReportUser(); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getConsultantServiceActionByIdUserAction = createAsyncThunk(
	"summaryService/getConsultantServiceActionByIdUserAction",
	async (body, { rejectWithValue }) => {
		try {
			const response = await getListConsultantUserServiceById(body); // Assumiamo che questa funzione restituisca una promessa
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const serviceSummarySlice = createSlice({
	name: "serviceSummary",
	initialState: {
		data: [],
		singleUserService: {},
		reportUser: [],
	},
	reducers: {
		setSingleUser: (state, action) => {
			state.singleUserService = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getConsultantServiceAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getConsultantServiceAction.fulfilled, (state, action) => {
				if (action.payload.length > 0) state.data = action.payload;
			})
			.addCase(getConsultantServiceAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				SweetAlert.fire({
					icon: "error",
					text: "Errore durante il caricamento dei dati",
				});
			})

			.addCase(getConsultantServiceActionByIdUserAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(
				getConsultantServiceActionByIdUserAction.fulfilled,
				(state, action) => {
					state.singleUserService = action.payload;
				}
			)

			.addCase(
				getConsultantServiceActionByIdUserAction.rejected,
				(state, action) => {
					// Quando la chiamata fallisce
					state.loading = false;
					state.error = action.payload || "Errore sconosciuto";
					SweetAlert.fire({
						icon: "error",
						text: "Errore durante il caricamento dei dati",
					});
				}
			)

			.addCase(getConsultantReportUserAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getConsultantReportUserAction.fulfilled, (state, action) => {
				state.reportUser = action.payload.data;
			})

			.addCase(getConsultantReportUserAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				SweetAlert.fire({
					icon: "error",
					text: "Errore durante il caricamento dei dati",
				});
			});
	},
});

export const { setSingleUser } = serviceSummarySlice.actions;
export const selectUser = (state) => state.user.type;
export const selectIsVerify = (state) => state.user.verify;
export const selectUserData = (state) => state.user.data;

export const selectUserLoading = (state) => state.user.loading;
export const selectUserError = (state) => state.user.error;
export default serviceSummarySlice.reducer;
