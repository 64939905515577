import React, { useEffect } from "react";
import HeaderPlatform from "../CommonElements/HeaderPlatform/index";
import { Row, Col } from "reactstrap";
import Card from "../Components/Common/Card";
import { useDispatch, useSelector } from "react-redux";
import { getDataDashboardAction } from "../features/dashboard/dashboardSlices";
import { ReactSVG } from "react-svg";
import clickBoardDelete from "../assets/images/clipboard-close.svg";
import TransactionCard from "../Components/Common/TransactionChart";
import UserChart from "../Components/Common/UserChart";

const HomeAdmin = () => {
	const dispatch = useDispatch();
	const dataDashboard = useSelector((state) => state.dashboard.data);

	useEffect(() => {
		dispatch(getDataDashboardAction());
	}, []);

	return (
		<>
			<Row>
				<Col sm='12'>
					<HeaderPlatform
						title={
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "baseline",
									gap: 10,
									width: "100%",
								}}
							>
								<p
									style={{
										fontSize: 24,
										fontWeight: 600,
										margin: 0,
									}}
								>
									Dati Piattaforma
								</p>
							</div>
						}
					/>

					<Row style={{ display: "flex", alignItems: "center" }}>
						<Col
							md='6'
							lg='6'
						>
							<UserChart data={dataDashboard.userDays} />
						</Col>
						<Col
							md='2'
							lg='2'
						>
							<Card
								amount={dataDashboard.total}
								iconClass={<ReactSVG src={clickBoardDelete} />}
								title={"Utenti registrati"}
							/>
						</Col>
						<Col
							md='2'
							lg='2'
						>
							<Card
								amount={dataDashboard.total}
								iconClass={<ReactSVG src={clickBoardDelete} />}
								title={"Utenti ultimo mese"}
							/>
						</Col>
						<Col
							md='2'
							lg='2'
						>
							<Card
								amount={dataDashboard.consultant}
								iconClass={<ReactSVG src={clickBoardDelete} />}
								title={"Totale Consulenti"}
							/>
						</Col>
						<Row style={{ display: "flex", alignItems: "center" }}>
							<Col
								md='6'
								lg='6'
							>
								<TransactionCard data={dataDashboard.transactionDays} />
							</Col>
							<Col
								md='2'
								lg='2'
							>
								<Card
									amount={dataDashboard.transaction + "€"}
									iconClass={<ReactSVG src={clickBoardDelete} />}
									title={"Incassi totali"}
								/>
							</Col>
							<Col
								md='2'
								lg='2'
							>
								<Card
									amount={dataDashboard.totalService}
									iconClass={<ReactSVG src={clickBoardDelete} />}
									title={"Totale servizi erogati"}
								/>
							</Col>
							<Col
								md='2'
								lg='2'
							>
								<Card
									amount={
										(
											dataDashboard.transaction / dataDashboard.totalService
										).toFixed(2) + "€"
									}
									iconClass={<ReactSVG src={clickBoardDelete} />}
									title={"Costo medio servizio"}
								/>
							</Col>
						</Row>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default HomeAdmin;
