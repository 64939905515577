import client from "../index";

export const getMe = async () => {
	return client.get("/user");
};

export const updateMe = async (body) => {
	return client.post(`/user`, body);
};

export const updatePassword = async (body) => {
	return client.post("/user/password", body);
};

export const updateUserDataInvoice = async (body) => {
	return client.post("/invoice/profile", body);
};

export const getInvoiceData = async () => {
	return client.get("/invoice/profile");
};

export const changeConsultant = async (body) => {
	return client.post("/user/change-consultant", body);
};
