import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import Chart from "react-apexcharts";
import CardHeaderComponent from "../Common/Component/HeaderCard";

const createTransactionChart = (data) => {
	const categories = data?.map((item) => {
		const date = new Date(item.date);
		return date?.toLocaleDateString("it-IT", {
			day: "2-digit",
			month: "short",
		});
	});

	const numbers = data?.map((item) => item.number);
	const totals = data?.map((item) => item.total / 100);

	return {
		series: [
			{
				name: "Numero Transazioni",
				type: "column",
				data: numbers,
			},
			{
				name: "Totale (€)",
				type: "line",
				data: totals,
			},
		],
		options: {
			chart: {
				height: 240,
				type: "line",
				toolbar: {
					show: false,
				},
				stacked: false,
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				width: [1, 4],
				curve: "smooth",
			},
			xaxis: {
				categories: categories,
				type: "category",
			},
			yaxis: [
				{
					title: {
						text: "Numero Transazioni",
					},
					labels: {
						formatter: function (val) {
							return val.toFixed(0);
						},
					},
				},
				{
					opposite: true,
					title: {
						text: "Totale (€)",
					},
					labels: {
						formatter: function (val) {
							return "€" + val.toLocaleString();
						},
					},
				},
			],
			colors: ["#26695c", "#c29873"],
			fill: {
				type: ["solid", "gradient"],
				gradient: {
					shade: "light",
					type: "vertical",
					shadeIntensity: 0.4,
					inverseColors: false,
					opacityFrom: 0.9,
					opacityTo: 0.8,
					stops: [0, 100],
				},
			},
			tooltip: {
				shared: true,
				intersect: false,
				y: [
					{
						formatter: function (y) {
							return y.toFixed(0) + " transazioni";
						},
					},
					{
						formatter: function (y) {
							return "€" + y.toLocaleString();
						},
					},
				],
			},
			legend: {
				horizontalAlign: "left",
				position: "top",
			},
		},
	};
};

const TransactionCard = ({ data }) => {
	// Calcola il totale delle transazioni
	console.log(data);
	const totalTransactions = data?.reduce((acc, curr) => acc + curr.number, 0);
	// Calcola il totale in euro
	const totalAmount = data?.reduce((acc, curr) => acc + curr.total / 100, 0);

	const chartConfig = createTransactionChart(data);

	return (
		<Card>
			<CardHeader>
				<CardHeaderComponent
					title='Andamento Incassi'
					subtitle={`Totale: ${totalTransactions} transazioni - €${totalAmount.toLocaleString()}`}
					settingIcon={true}
				/>
			</CardHeader>
			<CardBody className='p-0'>
				<div id='transaction-chart'>
					<Chart
						options={chartConfig.options}
						series={chartConfig.series}
						type='line'
						height={240}
					/>
				</div>
			</CardBody>
		</Card>
	);
};

export default TransactionCard;
