import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import Chart from "react-apexcharts";
import CardHeaderComponent from "../Common/Component/HeaderCard";

const createUserRegistrationsChart = (data) => {
	const categories = data?.map((item) => {
		const date = new Date(item.date);
		return date.toLocaleDateString("it-IT", { day: "2-digit", month: "short" });
	});

	const numbers = data?.map((item) => item.number);

	return {
		series: [
			{
				name: "Nuove Registrazioni",
				data: numbers,
			},
		],
		options: {
			chart: {
				height: 240,
				type: "bar",
				toolbar: {
					show: false,
				},
			},
			plotOptions: {
				bar: {
					borderRadius: 8,
					columnWidth: "40%",
					dataLabels: {
						position: "top",
					},
				},
			},
			dataLabels: {
				enabled: true,
				offsetY: -20,
				style: {
					fontSize: "12px",
					colors: ["#304758"],
				},
			},
			xaxis: {
				categories: categories,
				type: "category",
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
			},
			yaxis: {
				title: {
					text: "Registrazioni",
				},
				labels: {
					formatter: function (val) {
						return val.toFixed(0);
					},
				},
				// Imposta il min a 0 e il max a 5 per una migliore visualizzazione
				min: 0,
				max: 5,
				tickAmount: 5,
			},
			colors: ["#26695c"],
			fill: {
				type: "gradient",
				gradient: {
					shade: "light",
					type: "vertical",
					shadeIntensity: 0.4,
					inverseColors: false,
					opacityFrom: 0.9,
					opacityTo: 0.8,
					stops: [0, 100],
				},
			},
			tooltip: {
				y: {
					formatter: function (y) {
						return y + " utenti";
					},
				},
			},
			grid: {
				borderColor: "#f1f1f1",
			},
		},
	};
};

const UserRegistrationsCard = ({ data }) => {
	// Calcola il totale delle registrazioni
	const totalRegistrations = data?.reduce((acc, curr) => acc + curr.number, 0);

	const chartConfig = createUserRegistrationsChart(data);

	return (
		<Card>
			<CardHeader>
				<CardHeaderComponent
					title='Registrazioni Utenti'
					subtitle={`Totale: ${totalRegistrations} nuovi utenti`}
					settingIcon={true}
				/>
			</CardHeader>
			<CardBody className='p-0'>
				<div id='user-registrations-chart'>
					<Chart
						options={chartConfig.options}
						series={chartConfig.series}
						type='bar'
						height={240}
					/>
				</div>
			</CardBody>
		</Card>
	);
};

export default UserRegistrationsCard;
