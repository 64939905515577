import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { Tabs, Card, Empty, Spin } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";

const StyledCard = styled(Card)`
	margin-bottom: 16px;
	border-radius: 8px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease;

	&:hover {
		transform: translateY(-2px);
		box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
	}

	.ant-card-body {
		padding: 16px;
	}

	@media (max-width: 576px) {
		margin-bottom: 12px;
	}
`;

const QuestionText = styled.h4`
	font-size: 1.1rem;
	font-weight: 600;
	color: #003f4a;
	margin-bottom: 8px;

	@media (max-width: 576px) {
		font-size: 1rem;
	}
`;

const AnswerText = styled.p`
	font-size: 1rem;
	color: #333;
	margin-bottom: 0;
	padding: 8px;
	background-color: #f5f5f5;
	border-radius: 4px;
	white-space: pre-wrap;
	word-break: break-word;

	@media (max-width: 576px) {
		font-size: 0.9rem;
	}
`;

const StyledTabs = styled(Tabs)`
	.ant-tabs-nav {
		margin-bottom: 24px;
	}

	.ant-tabs-tab {
		transition: all 0.3s ease;
		&:hover {
			color: #003f4a;
		}
	}

	.ant-tabs-tab-active {
		.ant-tabs-tab-btn {
			color: #003f4a !important;
		}
	}
`;

const Container = styled.div`
	padding: 1rem;
	background-color: white;
	border-radius: 8px;
	min-height: 400px;
`;

const EmptyContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 400px;
`;

const QuestionnaireAnswers = () => {
	const [activeTab, setActiveTab] = useState("it");
	const answers = useSelector((state) => state.dashboard.resultData);
	const loading = useSelector((state) => state.dashboard.loading);

	if (loading) {
		return (
			<EmptyContainer>
				<Spin size='large' />
			</EmptyContainer>
		);
	}

	if (!answers || answers.length === 0) {
		return (
			<EmptyContainer>
				<Empty
					description={
						activeTab === "it"
							? "Nessuna risposta disponibile"
							: "没有可用的答案"
					}
				/>
			</EmptyContainer>
		);
	}

	// Raggruppa le risposte multiple della stessa domanda (per checkbox)
	const groupedAnswers = answers.reduce((acc, curr) => {
		try {
			if (!acc[curr.question_id]) {
				acc[curr.question_id] = curr;
			} else if (curr.translations[activeTab]?.answer?.includes(",")) {
				// Se è una risposta multipla, concatena le risposte
				const existingAnswers = acc[curr.question_id].translations[
					activeTab
				].answer
					.split(",")
					.map((a) => a.trim());
				const newAnswers = curr.translations[activeTab].answer
					.split(",")
					.map((a) => a.trim());
				acc[curr.question_id].translations[activeTab].answer = [
					...new Set([...existingAnswers, ...newAnswers]),
				].join(", ");
			}
		} catch (error) {
			console.error("Error processing answer:", curr, error);
		}
		return acc;
	}, {});

	const renderAnswers = (language) => (
		<Row>
			{Object.values(groupedAnswers).map((answer) => (
				<Col
					xs={12}
					sm={12}
					md={6}
					lg={6}
					key={answer.question_id}
					className='mb-3'
				>
					<StyledCard>
						<QuestionText>
							{answer.translations[language]?.question}
						</QuestionText>
						<AnswerText>
							{answer.translations[language]?.answer || "-"}
						</AnswerText>
					</StyledCard>
				</Col>
			))}
		</Row>
	);

	const items = [
		{
			key: "it",
			label: "Italiano",
			children: renderAnswers("it"),
		},
		{
			key: "ch",
			label: "中文",
			children: renderAnswers("ch"),
		},
	];

	return (
		<Container>
			<StyledTabs
				defaultActiveKey='it'
				items={items}
				onChange={(key) => setActiveTab(key)}
				size='large'
				type='card'
			/>
		</Container>
	);
};

export default QuestionnaireAnswers;
