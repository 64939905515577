import { H5, P } from "../../AbstractElements";
import React from "react";
import { Card, CardBody } from "reactstrap";

const IncomeCard = ({ iconClass, amount, title }) => {
	return (
		<Card
			className='income-card card-primary'
			style={{ width: "100%" }}
		>
			<CardBody className='text-center'>
				<div className='round-box'>{iconClass}</div>
				<H5>{amount}</H5>
				<P>{title}</P>
			</CardBody>
		</Card>
	);
};

export default IncomeCard;
