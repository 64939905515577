// HomeAdmin.jsx
import React, { useEffect, useState } from "react";
import HeaderPlatform from "../CommonElements/HeaderPlatform/index";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	getDataDashboardAction,
	getUserConsultantAction,
} from "../features/dashboard/dashboardSlices";
import P5 from "../CommonElements/Headings/P5Element";
import DataTableUser from "../Components/Pages/Dashboard/DataTable";
import ModalAlert from "../CommonElements/ModalAlert";
import { BorderRadius } from "../Constant";
import { Flex, Select } from "antd";
import { changeUserConsultantAction } from "../features/user/userSlices";

const HomeAdmin = () => {
	const dispatch = useDispatch();
	const dataDashboard = useSelector((state) => state.dashboard.data);
	const consultantData = useSelector((state) => state.dashboard.consultantData);
	const loading = useSelector((state) => state.dashboard.loading);
	const [showModal, setShowModal] = useState(false);
	const [consultant, setConsultant] = useState(null);
	const [user, setUser] = useState(null);
	const [reload, setReload] = useState(0);

	useEffect(() => {
		dispatch(getUserConsultantAction());
	}, [dispatch]);

	const handleSubmit = async () => {
		try {
			await dispatch(
				changeUserConsultantAction({
					id_user: user.id_user,
					id_consultant: consultant,
				})
			).unwrap();

			dispatch(getUserConsultantAction());
			setReload((prev) => prev + 1);
			setShowModal(false);
			setConsultant(null);
			setUser(null);
		} catch (error) {
			console.error("Errore durante il cambio del consulente:", error);
		}
	};

	return (
		<>
			<Row>
				<Col sm='12'>
					<HeaderPlatform
						title={
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "baseline",
									gap: 10,
									width: "100%",
								}}
							>
								<p
									style={{
										fontSize: 24,
										fontWeight: 600,
										margin: 0,
									}}
								>
									Consulenti - Utenti
								</p>
							</div>
						}
					/>
				</Col>

				<Row style={{ display: "flex", alignItems: "center" }}>
					<Col sm='12'>
						<DataTableUser
							setShowModal={setShowModal}
							setUser={setUser}
							setConsultant={setConsultant}
							reload={reload}
						/>
					</Col>
				</Row>
			</Row>

			<ModalAlert
				showModal={showModal}
				setShowModal={setShowModal}
				okButtonTitle={"Abbina"}
				deleteButtonTitle={"Annulla"}
				style={{ width: "50%", BorderRadius: 50 }}
				onDelete={() => setShowModal(false)}
				onOk={handleSubmit}
				okButtonProps={{ loading: loading }}
				BodyComponent={() => (
					<Row style={{ display: "flex", flexDirection: "column" }}>
						<Col sm='12'>
							<h6>Scegli il consulente da abbinare all'utente</h6>
						</Col>
						<Col sm='12'>
							<P5>
								{user?.name} {user?.surname} | {user?.email}
							</P5>
						</Col>
						<Select
							style={{ width: "100%" }}
							value={consultant}
							onChange={(e) => setConsultant(e)}
						>
							{consultantData?.map((item) => (
								<Select.Option
									key={item.id_user}
									value={item.id_user}
								>
									{item?.name} {item.surname} | {item.email}
								</Select.Option>
							))}
						</Select>
						<span></span>
					</Row>
				)}
			/>
		</>
	);
};

export default HomeAdmin;
