import client from "../index";

export const getDataDashboard = async () => {
	return client.get("/dashboard");
};

export const getUserConsultant = async () => {
	return client.get("/dashboard/consultant-user");
};

export const getSurveyResults = async (id) => {
	return client.get(`/survey/answers/${id}`);
};
