// DataTableUser.jsx
import React, { useEffect } from "react";
import { Table, Tag, Button, Tooltip } from "antd";
import { ReactSVG } from "react-svg";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/it";
import userIcon from "../../../assets/images/icon/userprofile.svg";
import { useNavigate } from "react-router-dom";
import { getUserConsultantAction } from "../../../features/dashboard/dashboardSlices";
import FileIcon from "../../../assets/images/icon/file.svg";

moment.locale("it");

const formatDate = (date) => {
	const formattedDate = moment(date).format("DD MMMM YYYY");
	const spliced = formattedDate.split(" ");
	const month = spliced[1];
	spliced[1] = month.charAt(0).toUpperCase() + month.slice(1);
	return spliced.join(" ");
};

const DataTableUser = ({ setShowModal, setUser, reload, setConsultant }) => {
	const dispatch = useDispatch();
	const dataTable = useSelector((state) => state.dashboard.userConsultant);
	const loading = useSelector((state) => state.dashboard.loading);
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(getUserConsultantAction());
	}, [reload, dispatch]);

	const columns = [
		{
			title: "Nome",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Cognome",
			dataIndex: "surname",
			key: "surname",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Consulente abbinato",
			dataIndex: "consultant",
			key: "consultant",
			sorter: true,
			filters: [
				{ text: "Abbinato", value: true },
				{ text: "Non abbinato", value: false },
			],
			onFilter: (value, record) => record.consultant === value,
			render: (consultant) => (
				<span>
					{consultant ? (
						<Tag color='#26695c'>Abbinato</Tag>
					) : (
						<Tag color='warning'>Non abbinato</Tag>
					)}
				</span>
			),
		},
		{
			title: "Consulente",
			render: (record) => (
				<span>
					{record.name_consultant && record.surname_consultant
						? `${record.name_consultant} ${record.surname_consultant}`
						: "-"}
				</span>
			),
		},
		{
			title: "Azioni",
			key: "actions",
			render: (record) => (
				<>
					<Tooltip title='Abbina Consulente'>
						<Button
							onClick={() => {
								setShowModal(true);
								setUser(record);
								setConsultant(record.id_consultant);
							}}
							icon={
								<ReactSVG
									src={userIcon}
									size={20}
								/>
							}
						/>
					</Tooltip>
					<Tooltip title='Visualizza Sondaggio'>
						<Button
							onClick={() => {
								navigate(`/survey/${record.id_user}`);
							}}
							icon={
								<ReactSVG
									src={FileIcon}
									size={20}
								/>
							}
						/>
					</Tooltip>
				</>
			),
		},
	];

	return (
		<div style={{ padding: "20px" }}>
			<Table
				columns={columns}
				dataSource={dataTable}
				loading={loading}
				locale={"it-IT"}
				pagination={{
					pageSize: 10,
				}}
				rowKey='id_user'
			/>
		</div>
	);
};

export default DataTableUser;
