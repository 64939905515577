// HomeAdmin.jsx
import React, { useEffect, useState } from "react";
import HeaderPlatform from "../CommonElements/HeaderPlatform/index";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSurveyResultsActions } from "../features/dashboard/dashboardSlices";

import { useNavigate, useParams } from "react-router-dom";
import QuestionnaireAnswers from "../Components/Pages/Dashboard/QuestionnaireAnswers";
import { ReactSVG } from "react-svg";
import { AlignLeft, ArrowLeft } from "react-feather";
import remove from "../assets/images/icon/add.svg";
const HomeAdmin = () => {
	const dispatch = useDispatch();
	const loading = useSelector((state) => state.dashboard.loading);
	const { id } = useParams();
	const navigate = useNavigate();
	useEffect(() => {
		dispatch(getSurveyResultsActions(id));
	}, [dispatch]);

	return (
		<>
			<Row>
				<Col sm='12'>
					<HeaderPlatform
						title={
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "baseline",
									gap: 10,
									width: "100%",
								}}
							>
								<ReactSVG
									src={remove}
									onClick={() => navigate("/match")}
								/>{" "}
								<p
									style={{
										fontSize: 24,
										fontWeight: 600,
										margin: 0,
									}}
								>
									Risposte ai sondaggi
								</p>
							</div>
						}
					/>
				</Col>

				<Row style={{ display: "flex", alignItems: "center" }}>
					<Col sm='12'>
						<QuestionnaireAnswers />
					</Col>
				</Row>
			</Row>
		</>
	);
};

export default HomeAdmin;
