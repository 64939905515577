// Routes.jsx
import { useSelector } from "react-redux";

import Home from "../Components/Pages/Home";
import DetailService from "../Components/Pages/Service/DetailService";
import ChatApp from "../pages/chat";
import Appointment from "../pages/appointment";
import Settings from "../pages/settings";
import AvailableSlots from "../pages/available-slots";
import ClientPage from "../pages/clients";
import ClientDetails from "../pages/clients-details";
import WizardService from "../Components/Pages/Service/Wizard";
import MyService from "../pages/my-service";
/* import MatchConsultantUser from "../pages/my-service";
import SurveyResult from "../pages/my-service"; */
import HomeAdmin from "../pages/home-admin";
import SurveyResult from "../pages/results-survey";
import MatchConsultantUser from "../pages/match-user";

// Configurazione delle rotte per utenti normali
const userRoutes = [
	{ path: "/", Component: <Home /> }, // Rotta di base
	{ path: `${process.env.PUBLIC_URL}/home`, Component: <Home /> },
	{ path: `${process.env.PUBLIC_URL}`, Component: <Home /> },
	{
		path: `${process.env.PUBLIC_URL}/service/:id`,
		Component: <DetailService />,
	},
	{
		path: `${process.env.PUBLIC_URL}/service/:id/wizard`,
		Component: <WizardService />,
	},
	{ path: `${process.env.PUBLIC_URL}/chat`, Component: <ChatApp /> },
	{ path: `${process.env.PUBLIC_URL}/chat/:id`, Component: <ChatApp /> },
	{ path: `${process.env.PUBLIC_URL}/settings`, Component: <Settings /> },
	{ path: `${process.env.PUBLIC_URL}/appointment`, Component: <Appointment /> },
];

const consultantRoutes = [
	{ path: `${process.env.PUBLIC_URL}/home`, Component: <ChatApp /> },
	{ path: `${process.env.PUBLIC_URL}/clients`, Component: <ClientPage /> },
	{
		path: `${process.env.PUBLIC_URL}/clients-details/:id`,
		Component: <ClientDetails />,
	},
	{
		path: `${process.env.PUBLIC_URL}/available-slots`,
		Component: <AvailableSlots />,
	},
	{ path: `${process.env.PUBLIC_URL}/chat`, Component: <ChatApp /> },
	{ path: `${process.env.PUBLIC_URL}/chat/:id`, Component: <ChatApp /> },
	{ path: `${process.env.PUBLIC_URL}/settings`, Component: <Settings /> },
	{ path: `${process.env.PUBLIC_URL}/my-service`, Component: <MyService /> },
	{ path: `${process.env.PUBLIC_URL}/appointment`, Component: <Appointment /> },
];

const adminRoutes = [
	{
		path: `${process.env.PUBLIC_URL}/home`,
		Component: <HomeAdmin />,
	},

	{
		path: `${process.env.PUBLIC_URL}/survey/:id`,
		Component: <SurveyResult />,
	},

	{
		path: `${process.env.PUBLIC_URL}/match`,
		Component: <MatchConsultantUser />,
	},
];

// Seleziona il set di rotte corretto in base al ruolo dell'utente
const RoutesConfig = () => {
	const isAdmin = useSelector((state) => state.user.data.cod_type) === "9999";
	const isConsultant = useSelector((state) => state.user.data.cod_type) === "2";
	const routes = isAdmin
		? adminRoutes
		: isConsultant
		? consultantRoutes
		: userRoutes;
	console.log(routes, isAdmin);
	return routes;
};

// Esportiamo `RoutesConfig` come default
export default RoutesConfig;
