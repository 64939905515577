import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
	getDataDashboard,
	getUserConsultant,
	getSurveyResults,
} from "../../api/dashboard";
import SweetAlert from "sweetalert2";

export const getDataDashboardAction = createAsyncThunk(
	"dashboard/getData",
	async (body, { rejectWithValue }) => {
		try {
			const response = await getDataDashboard(); // Assumiamo che questa funzione restituisca una promessa
			console.log(response);
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getSurveyResultsActions = createAsyncThunk(
	"dashboard/getSurveyResults",
	async (id, { rejectWithValue }) => {
		try {
			const response = await getSurveyResults(id); // Assumiamo che questa funzione restituisca una promessa
			console.log(response);
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getUserConsultantAction = createAsyncThunk(
	"dashboard/getUserConsultant",
	async (body, { rejectWithValue }) => {
		try {
			const response = await getUserConsultant(); // Assumiamo che questa funzione restituisca una promessa
			console.log(response);
			return response.data;
		} catch (error) {
			// Gestisci l'errore qui e restituiscilo
			if (error.response && error.response.status === 401) {
				// Errore 401 (non autorizzato)
				return rejectWithValue(
					"Non autorizzato. Controlla le tue credenziali."
				);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const dashboardSlice = createSlice({
	name: "dashboard",
	initialState: {
		data: { transactionDays: [], userDays: [] },
		singleUserService: {},
		reportUser: [],
		userConsultant: [],
		consultantData: [],
		resultData: [],
		loading: false,
	},
	reducers: {
		setSingleUser: (state, action) => {
			state.singleUserService = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getDataDashboardAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getDataDashboardAction.fulfilled, (state, action) => {
				console.log(action.payload);
				state.loading = false;
				state.data = action.payload;
			})
			.addCase(getDataDashboardAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				SweetAlert.fire({
					icon: "error",
					text: "Errore durante il caricamento dei dati",
				});
			})

			.addCase(getSurveyResultsActions.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getSurveyResultsActions.fulfilled, (state, action) => {
				console.log(action.payload);
				state.loading = false;
				state.resultData = action.payload;
			})
			.addCase(getSurveyResultsActions.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				SweetAlert.fire({
					icon: "error",
					text: "Errore durante il caricamento dei dati",
				});
			})

			.addCase(getUserConsultantAction.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getUserConsultantAction.fulfilled, (state, action) => {
				state.userConsultant = action.payload.user;
				state.loading = false;

				state.consultantData = action.payload.consultant;
			})
			.addCase(getUserConsultantAction.rejected, (state, action) => {
				// Quando la chiamata fallisce
				state.loading = false;
				state.error = action.payload || "Errore sconosciuto";
				SweetAlert.fire({
					icon: "error",
					text: "Errore durante il caricamento dei dati",
				});
			});
	},
});

export const { setSingleUser } = dashboardSlice.actions;
export const selectData = (state) => state.data;
export default dashboardSlice.reducer;
